import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from "react-helmet";
import {
  Container,
  Row,
  CardGroup,
  Col,
  Media,
  CardDeck,
  Button
} from "reactstrap";
import {
  CircularProgress
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import Layout from '../components/Layout';
import HeadMeta from '../components/HeadMeta';
import Title from '../components/UIComponents/Title';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import TriDymeIcon from '../media/TriDymeIconV1.svg';
import CardApp from '../components/UIComponents/Cards/CardApp';

import tridymeFeatures from '../data/features';
import axios from 'axios';

const {
  REACT_COMPANY_NAME,
  REACT_APP_URL
} = process.env;

const metaData = {
  en: {
    title: `TriDyme | Web Apps`,
    pageTitle: "Web Apps",
    description: "TriDyme offers a marketplace of applications that can be used to compute structural analysis and to visualize BIM models. Moreover, TriDyme helps construction's companies to develop their own cloud-based (online) applications."
  },
  fr: {
    title: `TriDyme | Applications Web`,
    pageTitle: "Applications Web",
    description: "TriDyme propose une marketplace d'applications permettant notamment de calculer des structures pour la construction ainsi que de visualiser des maquettes numériques BIM. De plus, TriDyme a vocation à aider les entreprises du secteur de la construction à développer leurs propres applications cloud."
  }
};


const WebAppsPage = ({
  pageContext: { locale },
}) => {
  const [webApps, setWebApps] = useState(tridymeFeatures.applications.applicationsList);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    async function getData() {
      try {
        setLoading(true);
        const { data } = await axios.get(`https://backend.tridyme.com/api/applications`);
        console.log('DATA', data);
        setWebApps(data);
        setLoading(false);
      } catch (error) {
        console.log('Error: Load applications');
      }
    };
    getData();
  }, []);
  return (
    <Layout locale={locale}>
      <HeadMeta
        locale={locale}
        metaData={metaData}
      />
      <Container className="webappsContainer">
        <Row>
          <Title
            icon={TriDymeIcon}
            title={metaData[locale].pageTitle}
            description={metaData[locale].description}
          // itemNumber={data.allMarkdownRemark.totalCount}
          />
        </Row>
        <hr />
        <Row>
          {loading ?
            <>
              <CircularProgress />
            </>
            :
            <Col lg={12}>
              {
                tridymeFeatures.applications.categoriesList.map((category, i) => (
                  <div key={i}>
                    <Row>
                      <Col lg={12}>
                        <h4 className="appgrouptitle">{category.label}</h4>
                      </Col>
                    </Row>
                    <CardDeck>
                      {
                        // tridymeFeatures.applications.applicationsList.map((app, index) => (
                        webApps.map((app, index) => (
                          <div key={index}>
                            { (category.value === app.category && app.company !== 'Aryatowers')
                              &&
                              <Zoom
                              >
                                <CardApp
                                  id={app.id}
                                  key={index}
                                  colnum={4}
                                  imgsrc={app.imgsrc}
                                  title={app.title}
                                  subtitle={app.category}
                                  description={app.description}
                                  link={`https://app.tridyme.com${app.link}`}
                                  tags={app.tags}
                                  href={`https://app.tridyme.com${app.link}`}
                                  status={app.status}
                                  logoCompany={app.logoCompany}
                                  company={app.company}
                                  video={app.video}
                                // openModal={showVideo}
                                />
                              </Zoom>
                            }
                          </div>
                        ))
                      }
                    </CardDeck>
                    <br />
                  </div>
                ))
              }
            </Col>
          }

        </Row>
        <Row>
          <Col md={4} />
          <Col md={4} className="align-center">
            <Button
              className="mainButton"
              href={`https://app.tridyme.com/dashboard`}
              color="primary"
              size="lg">
              Voir Plus
            </Button>
          </Col>
          <Col md={4} />
        </Row>
        <Row className="bottomPage">
          <br className="bottomPage" />
        </Row>
      </Container>
    </Layout>
  );
}

WebAppsPage.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired
  }).isRequired
}

export default WebAppsPage;
